.sidebar {
  width: 20%;
  height: 100vh;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #a88e1b;
  @extend %flex-col-center-start;
  padding: 2rem 1rem;
  @extend %h4-typo;
  overflow-y: auto;
}

.accordionFilters {
  width: 47%;
  min-height: 40px;
  @extend %flex-col-start-start;
  padding: 0.6rem;
  background-color: $grey;
  margin: 0.5rem 0;

  &--btn {
    padding: 0 0.5rem;
    width: 100%;
    height: 35px;
    @extend %flex-row-center-start;
  }
  &--check {
    width: 23px;
    height: 23px;
    border-radius: 25px;
    display: inline-block;
    border: 2px solid $greyDark;
    margin-right: 0.7rem;
    cursor: pointer;
  }
  &--title {
    width: 100%;
    font-family: 'Inter Medium';
    font-size: 12px;
    color: $dark;
    margin: 0 0 0 auto;
    @extend %flex-row-center-start;
    height: 35px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
  }
  &--badge {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $yellow;
    display: inline-block;
    margin-left: 0.5rem;
  }
  &--content {
    width: 100%;
    @extend %flex-col-start-start;
    overflow: hidden;
  }
  &--close {
    width: 100%;
    height: 32px;
    @extend %flex-row-center-between;
    // padding: 0.8rem 0;
    background-color: transparent;
    border: 0;
    outline: none;
    padding: 0 1rem;
    cursor: pointer;
    span {
      font-family: 'Inter Medium';
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize;
      color: $yellow;
    }
  }
}
