.customerCompositionsView {
  width: 100%;
  height: 100vh;
  @extend %flex-row-start-between;
  overflow: hidden;
  &--picturesBlock {
    width: 30%;
    height: 100%;
    @extend %flex-col-start-start;
    overflow-y: auto;
    padding: 1rem;
    background-color: $whiteBlue;
    gap: 1rem;
    &-imgBlock {
      width: 100%;
      position: relative;
      @extend %flex-col-center-center;
      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: 0.5rem;
      }
      button {
        width: 50px;
        height: 50px;
        top: 50%;
        border-radius: 50px;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: 10;
        cursor: pointer;
        outline: none;
        border: 0;
        margin: 0 0.2rem;
        background-color: $dark;
        transition: 0.3s;
        @extend %flex-col-center-center;
        visibility: hidden;
        &:hover {
          transition: 0.3s;
        }
      }
      &:hover button {
        visibility: visible !important;
      }
    }
  }
  &--productsAnswersBlock {
    width: 40%;
    height: 100%;
    @extend %flex-col-start-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    &--header {
      width: 100%;
      @extend %flex-row-center-between;
      padding: 0 1rem;
    }
    &--remove {
      width: 25px;
      height: 25px;
      @extend %flex-col-center-center;
      background-color: $red;
      cursor: pointer;
      color: $white;
      font-size: 14px;
      font-family: 'Inter Bold';
      border-radius: 4px;
      border: 0;
    }
    &--title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &--card {
      width: 145px;
      height: 80px;
      @extend %flex-col-center-center;
      background-color: #fafafa;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      padding: 1rem;
      font-family: 'Inter Bold';
      font-size: 14px;
      color: $dark;
      cursor: pointer;
    }
    &--list {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
    }
  }
  &--questionsBlock {
    width: 100%;
    @extend %flex-col-start-start;
    &--title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &--questionsList {
      width: 100%;
      @extend %flex-col-start-start;
    }
  }
  &--ingredientsBlock {
    width: 100%;
    @extend %flex-col-start-start;
    background-color: $white;

    &--title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &--inputSearch {
      width: 100%;
      @extend %flex-col-start;
      position: relative;
      label {
        @extend %h3-typo;
        color: $dark;
        margin-bottom: 0.4rem;
      }
      input {
        @extend %inputStyle;
      }
    }
    &--ingredients {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
      padding: 1rem 0;
      overflow-y: auto;
    }
  }
  &--compositionsBlock {
    width: 30%;
    height: 100%;
    @extend %flex-col-center-between;
    padding: 1rem;
    background-color: $whiteBlue;

    &--header {
      width: 100%;
      @extend %flex-row-center-between;
      margin-bottom: 1rem;
    }
    &--cards {
      width: 100%;
      height: 90%;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      @extend %flex-col-center-start;
      padding: 1rem;
    }
    &--compositions {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @extend %flex-col-start-start;
      position: relative;
    }
    &--compositionsTitle {
      @extend %h2-typo;
      color: $dark;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      background-color: $whiteBlue;
      padding: 0.5rem;
      z-index: 100;
    }
    &--rightBlock {
      width: 30%;
      height: 100%;
      @extend %flex-col-center-between;
      padding: 1rem;
      background-color: $whiteBlue;
      &-list {
        width: 100%;
        height: 90%;
        border: 1px solid #dbdbdb;
        border-radius: 10px;
        @extend %flex-col-center-start;
        gap: 1rem;
        overflow-y: auto;
      }
      &-text {
        @extend %h2-typo;
        color: $dark;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        width: 100%;
        background-color: $whiteBlue;
        padding: 0.5rem;
        z-index: 100;
      }
      &-filterBtn {
        width: 100%;
        height: 40px;
        background-color: #f5f5f5;
        border-radius: 8px;
        @extend %flex-row-center-between;
        padding: 0 0.5rem;
        border: 1px solid #dfdfdf;
        margin: 0.4rem 0;
        cursor: pointer;
        span {
          &:first-child {
            @extend %h2-typo;
            color: $dark;
          }
          &:last-child {
            width: 25px;
            height: 25px;
            border: 2px solid #dbdbdb;
            border-radius: 25px;
          }
        }
      }
    }
    &-maybeComps {
      margin-top: 1rem;

      &-Item {
        width: 100%;
        padding: 1rem;
        @extend %flex-col-start-start;
        margin-inline: auto;
        border-radius: 1rem;
        gap: 1rem;
        overflow-y: auto;
        background-color: $red;
      }
    }
  }
  &--btns {
    width: 100%;
    margin: auto 0 0;
    @extend %flex-row-center-between;
    padding: 1rem;
  }
}
