.moods {
  width: 100%;
  @extend %flex-wrap;
  padding: 1rem;
  justify-content: center;
  &--container {
    width: 100%;
    @extend %flex-col-center-center;
  }
  &--title {
    font-family: 'Inter Bold';
    font-size: 18px;
    margin: 1rem 0 0;
    text-transform: uppercase;
  }

  &--mood {
    height: 100px;
    background-color: transparent;
    border: 0;
    outline: none;
    @extend %flex-col-center-between;
    margin: 0 1rem 1rem 0;
    cursor: pointer;
    position: relative;
    img {
      width: 70px;
      // height: 70px;
      border: 2px solid transparent;
      border-radius: 10rem;
      padding: 0.5rem;
    }
    p {
      font-family: 'Inter Semi Bold';
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
    }
  }
}
