.user {
  width: 90%;
  @extend %flex-col-center-start;
  background: $white;
  border: 1px solid $grey;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 1rem auto;
  &--inputBlock {
    width: 100%;
    @extend %flex-row-center-between;
    margin: 0.5rem 0;
  }
  &--historicals {
    width: 100%;
    @extend %flex-col-start-start;
    &-thead {
      width: 100%;
      height: 45px;
      @extend %flex-row-center-start;
      background-color: $grey;
      padding: 0 1rem;
      border-radius: 4px;
      span {
        flex: 2;
        font-size: 14px;
        font-weight: 600;
        &:last-child {
          flex: 8;
        }
      }
    }
    &-trow {
      width: 100%;
      min-height: 45px;
      @extend %flex-row-start-start;
      border-bottom: 1px solid $grey;
      padding: 1rem;
      span {
        flex: 2;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5rem;

        &:last-child {
          flex: 8;
        }
      }
    }
  }
}
