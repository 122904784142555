.cities {
  width: 100%;
  @extend %flex-col-start-start;
  background-color: white;
  border-radius: 8px;
  padding: 1rem 0;
  &--tags {
    width: 100%;
    @extend %flex-col-start-start;
    padding: 1rem 2rem;
  }
  &--tagsList {
    @extend %flex-wrap;
  }

  &--letters {
    width: 100%;
    @extend %flex-wrap;
    margin: 1rem 0;
    span {
      height: 35px;
      @extend %flex-row-center-start;
      margin-right: 1rem;
      font-family: 'Inter Bold';
      font-size: 16px;
    }
  }
  &--letter {
    width: 35px;
    height: 35px;
    margin: 0.2rem;
    border: 1px solid $greyDark;
    background-color: $white;
    font-family: 'Inter Bold';
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    &-active {
      background-color: $yellow !important;
      border-color: $yellow !important;
      color: $white !important;
    }
  }
  &--footer {
    width: 100%;
    @extend %flex-row-end-between;
    background-color: $white;
    margin-top: auto;
    padding: 1rem;
    border-radius: 8px;
    &-page {
      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
