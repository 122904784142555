%inputStyle {
  width: 100%;
  height: 42px;
  background-color: $white;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 0.5rem;
  box-sizing: border-box;
  outline: none;
}
.fields {
  @extend %flex-col-start;
  position: relative;
  &--label {
    @extend %h3-typo;
    color: $dark;
    margin-bottom: 0.4rem;
  }
  &--pillsField {
    width: 100%;
    height: 42px;
    background-color: aqua;
    background-color: $white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 0.5rem;
    padding-right: 0;
    box-sizing: border-box;
    outline: none;
    @extend %flex-row-center-between;
    input {
      width: calc(100% - 160px);
      height: 42px;
      background-color: transparent;
      border: 0;
      padding: 0.5rem;
      box-sizing: border-box;
      outline: none;
    }
  }
  &--input {
    @extend %inputStyle;
  }
  &--link {
    width: 100%;
    height: 44px;
    @extend %flex-row-center-start;
    div {
      padding: 9px 12px 9px 13px;
      @extend %flex-row-center-center;
      min-width: 44px;
      height: 44px;
      background-color: $dark;
      border: 1px solid $grey;
      border-radius: 6px 0px 0px 6px;
      font-family: 'Inter Medium';
      font-weight: 500;
      font-size: 12px;
      color: $white;
    }
    &-input {
      @extend %inputStyle;
      border-radius: 0 6px 6px 0;
    }
  }

  &--selectBlock {
    border: 1px solid $greyDark;
    box-sizing: border-box;
    width: 100%;
    @extend %flex-row-center-between;
    height: 42px;
    padding: 0.5rem;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    outline: none;
  }

  &--selectPills {
    width: 100%;
    @extend %flex-col-start-start;
    &-field {
      min-height: 60px !important;
      height: inherit !important;
      @extend %inputStyle;
      @extend %flex-row-start-between;
      padding: 0 0.5rem;
    }
    &-pillsBlock {
      width: calc(100% - 16px);
      min-height: 60px !important;
      box-sizing: border-box;
      @extend %flex-wrap;
      padding: 0.5rem 0;
      input {
        // border: 0;
        outline: none;
        height: 35px;
        border: 1px solid #d1d5db;
        border-radius: 4px;
        width: 180px;
        @extend %h4-typo;
        padding: 0.5rem;
        margin: 0.2rem;
        &::placeholder {
          color: $greyDark;
        }
      }
    }
    &-list {
      width: 100%;
      min-height: 180px;
      max-height: 250px;
      background-color: $white;
      @extend %flex-col-center-start;
      overflow-y: auto;
      margin: 0.5rem 0;
      border: 1px solid $grey;
      border-radius: 6px;
      padding: 0.5rem 0;
      box-sizing: border-box;
    }
    &-item {
      width: 100% !important;
      height: 30px !important;
      padding: 0.4rem !important;
      text-align: left !important;
      outline: none;
      @extend %h3-typo;
      border: 0;
      cursor: pointer;
      position: inherit !important;
    }
  }
  &--pills {
    min-width: 80px;
    @extend %flex-row-center;
    padding: 0.5rem 0.8rem;
    border-radius: 20px;
    background-color: $grey;
    margin: 0 0.5rem;
    @extend %h3-typo;
    color: $dark;
    text-transform: uppercase;
    margin: 0.2rem;
  }
  &--inputCopy {
    @extend %inputStyle;
    padding: 0.1rem;
    @extend %flex-row-center-between;
    &-successText {
      width: 150px;
      height: 100%;
      border-radius: 7px;
      background-color: $yellow;
      color: white;
      @extend %h3-typo;
      @extend %flex-col-center-center;
      transition: 0.5s;
    }
    input {
      width: calc(100% - 150px);
      height: 100%;
      border: 0;
      outline: none;
      background-color: $white;
      @extend %h3-typo;
      &::placeholder {
        color: $greyDark;
      }
    }
  }
  &--checkbox {
    @extend %flex-row-center-start;
    &-check {
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid $dark;
      display: inline-block;
      margin-right: 0.6rem;
      cursor: pointer;
      transition: 0.3s;
    }
    span {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  button {
    background-color: transparent;
    border: 0;
    outline: none;
    position: absolute;
    right: 0.5rem;
    bottom: 0.3rem;
    cursor: pointer;
  }
  &--selectWithSearch {
    &-block {
      width: 100%;
      @extend %flex-col-start-start;
      @extend %inputStyle;
      height: auto;
      min-height: 42px;
      padding: 0;
      position: relative;
    }
    &-btn {
      width: 100%;
      height: 42px;
      padding: 0.5rem;
      @extend %flex-row-center-between;
    }
    &-select {
      width: 100%;
      max-height: 300px;
      padding: 0.5rem;
      @extend %flex-col-start-start;
      overflow-y: auto;
      position: absolute;
      top: 44px;
      left: 0;
      background-color: $white;
      border-radius: 4px;
      z-index: 50;
      border: 1px solid #d1d5db;
      input {
        @extend %inputStyle;
        margin-bottom: 1rem;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 100;
      }
      button {
        position: relative !important;
        display: block;
        width: 100%;
        height: 42px;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #d1d5db;
        text-align: left;
        transition: 0.3s;
        &:hover {
          background-color: $grey !important;
        }
      }
    }
  }
}
