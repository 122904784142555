.viewFeedModal {
  &--container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @extend %flex-row-center-start;

    &--imageList {
      margin: 1px;
    }

    &--imageWrapper {
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
      flex-wrap: wrap;
      transition: all 0.5s;
      position: relative;

      &--imgItem {
        width: 33% !important;
        flex-basis: calc(33.33% - 10px);
        height: 300px !important;
        object-fit: cover;
        margin: 1px;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
      &--empty {
        height: 200px;
        width: 500px;
        margin: 50px auto;
        @extend %flex-col-center;
        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          opacity: 0.5;
        }
        h1 {
          text-align: center;
          font-weight: 900;
        }
      }
    }
  }

  &--footer {
    width: 100%;
    min-height: 60px;
    padding: 0.5rem 1rem;
    @extend %flex-row-center-end;
    position: sticky;
    position: -webkit-sticky;
    bottom: -1rem;
    background-color: $white;
  }
}
