.offline {
  width: 100vw;
  height: 100vh;
  @extend %flex-col-center-center;
  background-color: $whiteBlue;
  &--container {
    width: 400px;
    height: auto;
    background-color: $white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 2rem;
    margin: auto;
    @extend %flex-col-center-start;
  }

  &--title {
    font-family: 'Inter Bold';
    font-style: normal;
    font-size: 26px;
    text-align: center;
    color: $dark;
    margin: 0;
  }
  &--text {
    @extend %h3-typo;
    color: $dark;
    margin: 0.5rem 0;
  }
}
