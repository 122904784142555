//%display-flexDirection-alignItems-justifyContent

//FLEX COL
%flex-col-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
%flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
%flex-col-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
%flex-col-start-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
%flex-col-start-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
%flex-col-start-end {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
%flex-col-start-between {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
%flex-col-start-around {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
%flex-col-center-start {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
%flex-col-center-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
%flex-col-center-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
%flex-col-center-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
%flex-col-center-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
%flex-col-end-start {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
%flex-col-end-center {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
%flex-col-end-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
%flex-col-end-between {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
%flex-col-end-around {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}

//FLEX ROW
%flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
%flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
%flex-row-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
%flex-row-start-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
%flex-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
%flex-row-start-end {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
%flex-row-start-between {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
%flex-row-start-around {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
%flex-row-center-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
%flex-row-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
%flex-row-center-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
%flex-row-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
%flex-row-center-around {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
%flex-row-end-start {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
%flex-row-end-center {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
%flex-row-end-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
%flex-row-end-around {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
%flex-row-end-between {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

%flex-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
//Typography
%h1-typo {
  font-family: 'Inter Bold';
  font-size: 16px;
}
%h2-typo {
  font-family: 'Inter Bold';
  font-size: 13px;
}
%h3-typo {
  font-family: 'Inter Medium';
  font-size: 13px;
}
%h4-typo {
  font-family: 'Inter Semi Bold';
  font-size: 13px;
}
%h5-typo {
  font-family: 'Inter Semi Bold';
  font-size: 12px;
}
