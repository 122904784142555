.customer {
  width: 90%;
  @extend %flex-col-center-start;
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 8px;
  margin: 1rem auto;
  padding: 1.5rem 4rem 0;

  &--filters {
    display: flex;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 1rem;

    &--item {
      flex: 1;
      background-color: white;
      text-align: center;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-right: 1px solid $grey;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &-active {
        background-color: $yellow;
        color: $white;
      }
    }
  }

  &--packageBlock {
    @extend %h3-typo;
  }
  &--packageTitle {
    @extend %h1-typo;
    margin-right: 0.5rem;
  }
  &--packageText {
    @extend %h2-typo;
    color: $yellow;
    margin: 0 0.4rem;
  }
  &--inputBlock {
    width: 100%;
    @extend %flex-row-center-between;
    margin: 0.5rem 0;
  }
  &--textData,
  &--textData-btn {
    padding: 1rem;
    border: 1px dashed $greyDark;
    margin-right: 1rem;
    border-radius: 4px;
    font-family: 'Inter Medium';
    font-size: 14px;
    color: $dark;
  }
  &--textData-btn {
    background-color: $white;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    padding: 0.7rem 1rem;
  }
  &--responsable {
    width: calc(100% + 8rem);
    min-height: 275px;
    background-color: $grey;
    @extend %flex-col-start-start;
    padding: 1rem 4rem;
  }
  &--section {
    width: 100%;
    height: 44px;
    padding: 0.5rem 1rem;
    background-color: $grey;
    @extend %flex-row-center-start;
    margin-bottom: 1rem;
    &-title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 24px;
      color: $dark;
    }
  }
  //Hashtags panel
  &--tagsList {
    width: 100%;
    @extend %flex-wrap;
    margin-bottom: 2rem;
  }

  //Diary panel
  &--diarypanel {
    &-header {
      width: calc(100% + 8rem);
      min-height: 98px;
      @extend %flex-row-start-between;
      border: 0.5px solid $grey;
      border-radius: 6px;
      padding: 1rem 2rem;
      &-formulaInfos,
      &-strategyInfos {
        // flex: 1;
        @extend %flex-row-end-start;
      }
      &-actionBtn {
        @extend %flex-row-end-between;

        button {
          margin-left: 0.5rem !important;
        }
      }
      &-formulaTexts,
      &-strategyTexts {
        margin-right: 1rem;
        p {
          margin: 0 0 0.3rem;
          &:first-child {
            font-family: 'Inter Medium';
            font-size: 14px;
            color: $dark;
          }
          &:last-child {
            font-family: 'Inter Bold';
            font-size: 18px;
            color: $yellow;
          }
        }
      }
      &-filterBy {
        @extend %flex-row-center;

        p {
          @extend %h3-typo;
          margin-right: 1rem;
        }
        button {
          padding: 0.5rem;
          border: 1px solid $greyDark;
          @extend %h3-typo;
          border-radius: 4px;
          margin: 0 0.2rem;
          background-color: transparent;
          transition: all 0.3s;
          cursor: pointer;
          &:hover {
            border-color: $yellow;
            transform: scale(1.02);
          }
        }
        &-active {
          background-color: $yellow !important;
          color: $white !important;
          border-color: $yellow !important;
        }
      }
    }
    &-listMonths {
      width: calc(100% + 8rem);
      @extend %flex-col-start-start;
      background-color: white;
      border: 0.5px solid $grey;
      border-radius: 6px;
    }
    &-formula,
    &-strategy {
      width: 48%;
      min-height: 82px;
      // @extend %flex-row-center-start;
      padding: 0.5rem 1rem;
      background: $white;
      border: 1px solid $grey;
      border-radius: 30px;
      margin: 0 1rem 1rem 0;
      position: relative;
      &-number {
        font-family: 'Inter Semi Bold';
        font-size: 22px;
        color: $greyDark;
        margin-right: 0.5rem;
      }
      &-img {
        margin-right: 1rem;
        position: relative;
        span {
          width: 64px;
          height: 64px;
          @extend %flex-row-center-center;
          cursor: pointer;
          background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
          box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
          border-radius: 13px;
        }
      }
      &-date {
        font-family: 'Inter Medium';
        font-size: 14px;
        color: $dark;
      }
      &-btns {
        width: 130px;
        @extend %flex-row-center-end;
        margin-left: auto;
        button {
          width: 45px;
          height: 45px;
          @extend %flex-col-center-center;
          cursor: pointer;
          border-radius: 45px;
          outline: none;
          border: 0;
          margin: 0 0.2rem;
          &:first-child {
            background-color: $dark;
          }
          &:last-child {
            background-color: $yellow;
          }
        }
      }
    }
    &-strategy {
      min-height: 100px !important;

      > div {
        width: auto;

        @extend %flex-row-center-start;
      }
    }
    &-formula {
      img {
        margin: 0 1rem 0 0.5rem;
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 13px;
        cursor: pointer;
      }
    }

    &-formula2 {
      width: 15%;
      min-height: 82px;
      @extend %flex-col-center-center;
      margin: 0 0.7rem 0.7rem 0;
      border: 1px solid $grey;
      border-radius: 8px;

      overflow: hidden;
      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
      }
      &-img {
        position: relative;
        width: 100%;
        span {
          width: 100%;
          height: 120px;
          @extend %flex-row-center-center;
          cursor: pointer;
          background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
          box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
          border-radius: 8px;
        }
      }
    }

    &-imgDownload {
      @extend %flex-col-center-center;
      p {
        font-family: 'Inter Bold';
        font-size: 18px;
        color: $dark;
        margin: 0 0 1rem;
        text-transform: capitalize;
      }
      &-img {
        width: 116px;
        height: 116px;
        @extend %flex-col-center-center;
        position: relative;
        box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
        border-radius: 13px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        a {
          width: 49px;
          height: 49px;
          @extend %flex-col-center-center;
          border-radius: 49px;
          background: rgba(168, 142, 27, 0.7);
          border: 0;
          outline: none;
          position: absolute;
          z-index: 10;
          cursor: pointer;
        }
      }
    }
  }
  //univers panel
  &--universpanel {
    &-header {
      width: 100%;
      padding: 0 0 1rem;
      @extend %flex-col-center-center;
      font-family: 'Inter Bold';
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
    }

    &-btnRadio {
      width: 100%;
      @extend %flex-row-center-between;
      background-color: white;
      // height: 36px;
      padding: 0.5rem;
      border: 1px solid $white;
      border-radius: 8px;
      cursor: pointer;
      margin: 0.2rem 0;
      transition: all 0.4s ease-in-out;
      text-align: left;
      span {
        &:first-child {
          font-family: 'Inter Medium';
          font-size: 13px;
          color: $dark;
          max-width: 85%;
        }
        &:last-child {
          width: 14px;
          height: 14px;
          background-color: transparent;
          border: 1px solid $grey;
          border-radius: 30px;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
  &--signaturepanel {
    &-text {
      margin-left: auto;
      font-family: 'Inter Bold';
      font-size: 14px;
      color: $dark;
    }

    &-field {
      width: 100%;
      min-height: 140px;
      border: 1px solid $greyDark;
      border-radius: 8px;
      margin: 0.5rem 0;
      @extend %flex-col-start-center;
      padding: 1rem;
      &-label {
        font-family: 'Inter Bold';
        font-weight: 700;
        font-size: 18px;
        color: $dark;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
      &-inputBlock {
        width: 100%;
        @extend %flex-row-center-between;
        input {
          width: calc(100% - 70px);
          height: 45px;
          padding: 18px 13px;
          background-color: $white;
          border: 1px solid $greyDark;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 6px;
          outline: none;
        }
      }
      &-check {
        width: 35px;
        height: 35px;
        border: 2px solid #dbdbdb;
        border-radius: 40px;
        cursor: pointer;
      }
    }
  }

  &--diarypanel--container {
    > div {
      width: 100%;
    }
  }

  &--footer {
    margin-top: auto;
    width: 100%;
    height: 80px;
    @extend %flex-row-center-end;
    padding: 1rem 7rem;
    background-color: $white;
    position: sticky;
    position: -webkit-sticky;
    right: 0;
    bottom: 0;
    z-index: 150;
  }
}
