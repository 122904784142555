.productCompositions {
  width: 100%;
  height: 100vh;
  @extend %flex-row-start-between;
  background-color: $whiteBlue;
  overflow: hidden;
  //product components list view
  &--table {
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 1rem;
    @extend %flex-col-start-start;
    overflow-y: auto;
  }

  &--trow {
    width: 100%;
    min-height: 80px;
    background-color: $grey;
    border-radius: 8px;
    @extend %flex-row-center-between;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &--trowBadgeContainer {
    @extend %flex-row-center-start;
    gap: 1rem;
    margin-left: 1rem;

    &--badge {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &--trowBadge {
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    display: inline-block;
    // margin-left: auto;
    margin-right: 1rem;
  }

  &--shortname {
    font-family: 'Inter Bold';
    font-size: 14px;
    line-height: 17px;
    color: $dark;
    margin: 0 0 0.3rem;
  }
  &--trowInfos {
    @extend %flex-row-center-start;
    flex: 1;
    img {
      width: 65px;
      height: 64px;
    }
    span {
      font-family: 'Inter Medium';
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
    }
  }

  //product components gestion view

  &--questionsBlock {
    width: 20%;
    height: 100%;
    max-height: 99%;
    @extend %flex-col-start-start;
    padding: 1rem;
    background-color: $whiteBlue;
    overflow-y: scroll;
    &--title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &--questionsList {
      width: 100%;
      @extend %flex-col-start-start;
    }
  }

  &--ingredientsBlock {
    width: 50%;
    height: 100%;
    @extend %flex-col-start-start;
    padding: 1rem;
    background-color: $white;
    border: 1px solid #f5f5f5;

    &--title {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &--inputSearch {
      width: 100%;
      @extend %flex-col-start;
      position: relative;
      label {
        @extend %h3-typo;
        color: $dark;
        margin-bottom: 0.4rem;
      }
      input {
        @extend %inputStyle;
      }
    }
    &--ingredients {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
      padding: 1rem 0;
      overflow-y: auto;
    }
    &--btns {
      width: 100%;
      margin: auto 0 0;
      @extend %flex-row-center-between;
      padding: 1rem;
    }
  }

  &--compositionsBlock {
    width: 30%;
    height: 100%;
    @extend %flex-col-center-between;
    padding: 1rem;
    background-color: $whiteBlue;

    &--header {
      width: 100%;
      @extend %flex-row-center-between;
      margin-bottom: 1rem;
    }
    &--cards {
      width: 100%;
      height: 90%;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      @extend %flex-col-center-start;
      padding: 1rem;
    }
    &--compositions {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      @extend %flex-col-start-start;
      position: relative;
    }
    &--compositionsTitle {
      @extend %h2-typo;
      color: $dark;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      background-color: $whiteBlue;
      padding: 0.5rem;
      z-index: 100;
    }
    &-maybeComps {
      margin-top: 1rem;

      &-Item {
        width: 95%;
        padding: 1rem;
        @extend %flex-col-start-start;
        margin-inline: auto;
        border-radius: 1rem;
        gap: 1rem;
        overflow-y: auto;
        background-color: $red;
      }
    }
  }
}

.familyProdComposition--search {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: 1rem;

  & > input {
    width: 100%;
    border: 0;
    outline: none;
    padding: 0.5rem;
    font-family: 'Inter Semi Bold';
    font-size: 12px;
  }
}

//question card
.questionCard {
  width: 100%;
  min-height: 85px;
  padding: 0.5rem;
  @extend %flex-col-start-start;
  background-color: #f5f4ef;
  border: 1px solid;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  &--title {
    margin: 0 0 0.5rem;
    padding: 0;
    @extend %h1-typo;
    text-transform: uppercase;
    color: $dark;
  }
  &--list {
    width: 100%;
    @extend %flex-wrap;
  }
  &--itemsText {
    @extend %h3-typo;
    color: $dark;
    padding-left: 0.5rem;
    white-space: nowrap;
    margin: 0 0.3rem 0.3rem 0;
  }
}

//ingredient card
.ingredientCard {
  width: 145px;
  min-height: 150px;
  @extend %flex-col-center-between;
  background-color: #fafafa;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  padding: 1rem;
  position: relative;
  &--ingredient {
    font-family: 'Inter Bold';
    font-size: 14px;
    color: $dark;
    margin: 0 0 1rem;
  }
  &--btns {
    @extend %flex-row-center-center;
    span {
      font-family: 'Inter Bold';
      font-size: 14px;
      color: $dark;
    }
  }
  &--badge {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: $yellow;
    color: $white;
    @extend %flex-col-center-center;
    position: absolute;
    right: -15px;
    top: -15px;
    z-index: 20;
    border: 0;
    font-family: 'Inter Bold';
    font-size: 15px;
  }
  &--imgBlock {
    width: 100%;
    position: relative;
    @extend %flex-col-center-center;
  }
}

//create product component card
.createCompositionCard {
  width: 100%;
  min-height: auto;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: $dark;
  padding: 0.5rem;
  position: relative;

  &--input {
    width: 100%;
    height: 35px;
    border: 0;
    outline: none;
    border-radius: 4px;
    padding: 0 0.3rem;
    font-family: 'Inter Semi Bold';
    font-size: 12px;
  }
  &--badge {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: absolute;
    left: 1rem;
    top: 1rem;
    padding: 0.5rem;
    background-color: $grey;
  }
  &--removeBtn {
    width: 25px;
    height: 25px;
    @extend %flex-col-center-center;
    border-radius: 4px;
    background-color: $yellow;
    color: $white;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 10;
    font-size: 12px;
    border: 0;
    outline: none;
  }

  &--title {
    @extend %h1-typo;
    text-align: center;
    color: $yellow;
    margin: 0 auto 0.5rem;
    min-height: 30px;
    padding: 0.3rem;
    @extend %flex-row-center-center;
    width: calc(100% - 85px);
  }
  &--card {
    width: 100%;
    min-height: 130px;
    background-color: $grey;
    @extend %flex-row-start-between;
    padding: 0.5rem;
    border-radius: 9px;
    margin-top: 0.5rem;
  }
  &--upload {
    width: 110px;
    height: 115px;
    @extend %flex-col-center-center;
    background: $grey;
    border: 1px solid #d2d2d2;
    box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
    border-radius: 13px;
    cursor: pointer;
  }
  &--itemsText {
    @extend %h3-typo;
    color: $dark;
    padding-left: 0.5rem;
    white-space: nowrap;
    margin: 0 0.3rem 0.3rem 0;
  }
}

//composition card
.compositionCard {
  width: 100%;
  background-color: $grey;
  @extend %flex-row-start-between;
  padding: 0.5rem;
  border-radius: 9px;
  margin: 0 0 0.5rem;
  border: 1px solid $grey;
  &--imgBlock {
    position: relative;
    @extend %flex-col-center-center;
    margin-right: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 13px;
      object-fit: cover;
    }
    span {
      width: 62px;
      height: 62px;
      position: absolute;
      border-radius: 65px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @extend %flex-col-center-center;
      font-family: 'Inter Bold';
      font-size: 20px;
      color: $dark;
      z-index: 20;
      background-color: rgba(168, 142, 27, 0.5);
    }
  }
  &--items {
    width: calc(100% - 120px);
    @extend %flex-col-start-start;
  }
  &--list {
    flex: 1;
    @extend %flex-wrap;
  }
  &--itemsText {
    @extend %h3-typo;
    color: $dark;
    // white-space: nowrap;
    margin: 0 0.3rem 0.3rem 0;
  }
}
