%badge {
  width: 32px;
  height: 32px;
  border: 1px solid $yellow;
  border-radius: 30px;
  position: absolute !important;
  background-color: $white;
  position: relative;
  transition: 0.3s;
}
%openButton {
  width: 22px;
  height: 22px;
  border: 1px solid $yellow;
  border-radius: 30px;
  position: absolute !important;
  background-color: $white;
  position: relative;
  transition: 0.3s;
  @extend %flex-row-center-center;
}
%badge-selected-after {
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 18px;
  background-color: transparent;
  border: 1px solid $yellow;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
%badge-selected-before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: $yellow;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.familyQuiz {
  width: 100%;
  height: 100vh;
  @extend %flex-row-center-between;
  overflow: hidden;

  &--leftBlock {
    width: 270px;
    height: 100%;
    background-color: $grey;
    @extend %flex-col-center-start;
    &-header {
      width: 100%;
      height: 65px;
      @extend %flex-row-center-between;
      border-bottom: 2px solid $yellow;
      button {
        flex: 1;
        height: 100%;
        border: 0;
        background-color: transparent;
        font-family: 'Inter Bold';
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: $dark;
        outline: none;
        transition: 0.3s;
        cursor: pointer;
      }
    }
    &-headerBtnActive {
      background-color: $yellow !important;
      color: $white !important;
    }
  }

  &--leftBlockContent {
    width: 100%;
    height: calc(100% - 67px);
    overflow-y: auto;
    @extend %flex-col-center-start;
    padding: 1rem;
    &-addQuestionBtn {
      width: 100%;
      height: 45px;
      @extend %flex-row-center-between;
      border: 0;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      transition: 0.3s;
      margin-bottom: 0.5rem;
      span:first-child {
        font-family: 'Inter Bold';
        font-size: 14px;
        line-height: 17px;
        color: $dark;
      }
      span:last-child {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        background-color: $yellow;
        @extend %flex-col-center-center;
      }
      &:active {
        transform: scale(0.95);
      }
    }
    &-item {
      width: 100%;
      height: 60px;
      @extend %flex-row-center-start;
      background-color: $white;
      border: 1px solid $grey;
      transition: 0.3s;
      cursor: pointer;
      box-shadow: 0px 4px 4px rgba(187, 187, 187, 0.15);
      border-radius: 10px;
      margin: 0.5rem 0;
      overflow: hidden;
      outline: none;
      padding: 0.5rem;
      position: relative;
      transition: 0.3s;
      span:first-child {
        font-family: 'Inter Bold';
        font-size: 18px;
        line-height: 22px;
        color: $dark;
        width: 40px;
        height: 40px;
        border-radius: 6px;
        @extend %flex-col-center-center;
        background-color: #9ca3af;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        margin-right: 0.8rem;
      }
      span:last-child {
        @extend %flex-col-center-start;
        width: calc(100% - 50px);
        font-size: 14px;
        line-height: 17px;
        font-family: 'Inter Medium';
        color: $dark;
        text-align: left;
      }
      &:hover {
        border-color: $yellow;
      }
    }
    &-deleteBtn {
      width: 25px;
      height: 25px;
      background-color: $red;
      @extend %flex-col-center-center;
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translateY(-50%);
      cursor: pointer;
      transition: 0.3s;
      font-size: 13px;
      font-weight: 600;
      color: $white;
      border: 0;
      overflow: none;
      border-radius: 4px;
      visibility: hidden;
      transition: 0.3s;
    }
    &-item:hover &-deleteBtn {
      visibility: visible;
    }
  }

  &--rightBlock {
    width: calc(100% - 290px);
    height: 100%;
    background-color: $whiteBlue;
    @extend %flex-col-center-start;
    &-header {
      width: 100%;
      height: 65px;
      @extend %flex-row-center-between;
      background-color: $white;
      padding: 0.5rem 1rem;
    }
    &-headerTitle {
      font-family: 'Inter Bold';
      font-size: 18px;
      line-height: 22px;
      text-transform: uppercase;
      color: $dark;
    }
    &-headerBtns {
      flex: 1;
      @extend %flex-row-center-end;
    }
  }
  &--logicBlock,
  &--questionBlock {
    width: 90%;
    height: calc(100% - 80px);
    margin: auto auto 0;
    @extend %flex-col-center-start;
  }
  &--questionBlock {
    overflow-y: auto;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    &-options {
      width: 100%;
      @extend %flex-row-center-between;
    }

    &-labelSwitch {
      font-family: 'Inter Semi Bold';
      font-weight: 700;
      font-size: 14px;
      color: $dark;
      margin: 0 0.3rem;
      padding: 0;
    }

    &-input {
      border: 0;
      outline: none;
      background-color: transparent;
      margin: 0 auto -0.3rem 0.4rem;
      font-family: 'Inter Semi Bold';
      font-weight: 700;
      font-size: 14px;
      color: $dark;
    }

    &-ingredients {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
      margin-top: 1rem;
    }

    &-ingredients-addBtn,
    &-ingredient {
      width: 155px;
    }

    &-ingredient {
      width: 31%;
      min-height: 40px;
      @extend %flex-col-center-center;
      border: 0;
      box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
      border-radius: 13px;
      background-color: $grey;
      cursor: pointer;
      border: 1px solid $yellow;
      position: relative;
      &-infos {
        padding: 0.5rem;
        width: 100%;
        height: auto;
        @extend %flex-col-center-center;
      }
      &-image {
        width: 100%;
        background-color: $white;
        @extend %flex-col-center-center;
        img {
          width: 100%;
          height: 100%;
          max-width: 155px;
          object-fit: cover;
          margin: 0 auto auto;
        }
      }

      &-label {
        color: $dark;
        font-family: 'Inter Bold';
        font-size: 14px;
        margin: 0 0 auto;
        padding: 0;
        height: 40px;
        @extend %flex-col-center-center;
      }
      &-badge {
        left: -0.7rem;
        top: -0.7rem;

        @extend %badge;
      }
      &-selected::after {
        @extend %badge-selected-after;
      }
      &-selected::before {
        @extend %badge-selected-before;
      }
      &-openButton {
        right: -0rem;
        top: -0rem;
        @extend %openButton;
      }
    }
    &-specialities {
      width: 100%;
      @extend %flex-col-start-start;
    }
    &-speciality {
      cursor: pointer;
      @extend %flex-row-center-start;
      width: 100%;
      height: 55px;
      border: 0;
      outline: none;
      background-color: transparent;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: contain;
      }
      &-label {
        color: $dark;
        font-family: 'Inter Bold';
        font-size: 14px;
        margin: 0 0 0 0.2rem;
        padding: 0 0 0 0.5rem;
        width: calc(100% - 70px);
        text-align: left;
      }
      &-badge {
        position: relative !important;
        width: 22px;
        height: 22px;
        border-radius: 22px;
        display: inline-block;
        @extend %badge;
        margin-left: auto;
      }

      &-selected::before {
        @extend %badge-selected-before;
      }
    }
  }
  &--logicBlock {
    overflow-y: hidden;
    background-color: transparent;
    &-header,
    &-container {
      background-color: $white;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      border: 1px solid $grey;
      overflow-y: auto;
    }
    &-header {
      width: 100%;
      height: 55px;
      @extend %flex-row-center-start;
      span {
        font-family: 'Inter Bold';
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: $dark;
      }
    }
    &-container {
      width: 100%;
      height: calc(100% - 72px);
      margin-top: auto;
      @extend %flex-col-start-start;
      padding: 1rem;
    }
    &-cards {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
    }
    &-card {
      width: 360px;
      height: 240px;
      @extend %flex-col-center-start;
      background-color: $grey;
      border: 1px solid $yellow;
      padding: 1rem;
      border-radius: 8px;
    }
  }

  &--noQuestionWarning {
    width: 90%;
    height: calc(100% - 80px);
    margin: auto auto 0;
    @extend %flex-col-center-center;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 8px;
    padding: 1rem 1.5rem;
    p {
      font-size: 16px;
      font-family: 'Inter Bold';
      color: $dark;
    }
  }
}
