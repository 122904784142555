.imagesLayout {
  width: 100%;
  height: 100vh;
  background-color: #f9fafb;
  @extend %flex-col-start-start;
  overflow: hidden;
  &--header {
    width: 100%;
    height: 70px;
    @extend %flex-row-center-start;
    background-color: white;
    padding: 1rem;
    &-filter {
      width: 380px;
      height: 45px;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      // overflow: hidden;
      position: relative;
      @extend %flex-row-center-between;
      select {
        width: calc(100% - 90px);
        height: 100%;
        border: 0;
        padding: 0 0.5rem;
        outline: none;
      }
      button {
        width: 80px;
        height: 100%;
        @extend %h2-typo;
        border: 0;
        outline: none;
        background-color: $yellow;
        color: $white;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: pointer;
      }
      input {
        width: calc(100% - 90px);
        height: 100%;
        border: 0;
        padding: 0 0.5rem;
        outline: none;
        background-color: transparent;
      }
      img.customer-avatar {
        width: 55px;
        height: 55px;
        border-radius: 55px;
        position: absolute;
        right: -1rem;
        object-fit: cover;
      }

      img.loader {
        width: 30px;
      }
    }
    &-filterBlock {
      width: calc(100% - 90px);
      height: 100%;
      border: 0;
      padding: 0 0.5rem;
      outline: none;
      position: relative;
      @extend %flex-row-center-between;
      input {
        width: 100%;
        height: 100%;
      }
      &-list {
        width: 100%;
        min-height: 45px;
        max-height: 300px;
        overflow-y: auto;
        padding: 0.5rem;
        background-color: white;
        position: absolute;
        top: 45px;
        left: 0;
        border-radius: 0.5rem;
        z-index: 100;
        p {
          padding: 0.5rem;
          cursor: pointer;
          border: 1px solid $greyDark;
          @extend %h2-typo;
          text-transform: capitalize;
          margin: 0.2rem 0;
          transition: 0.3s;
          border-radius: 4px;
          &:hover {
            color: $yellow;
            border-color: $yellow;
          }
        }
      }
    }
  }
  &--contain {
    width: 100%;
    height: calc(100% - 70px);
    @extend %flex-row-start-start;
    overflow: hidden;
  }
  &--sidebar {
    height: 100%;
    background-color: $white;
    overflow-x: hidden;
    @extend %flex-col-start-start;
    transition: all 0.3s;
    &-restoFilters {
      width: 100%;
      @extend %flex-col-start-start;
      padding: 1rem 0.5rem;
      border-bottom: 1px solid $greyDark;
    }
    &-moods {
      width: 100%;
      padding: 1rem 0.5rem 0;
      @extend %flex-row-start-center;
      flex-wrap: wrap;
      border-bottom: 1px solid #f5f5f5;
      // margin-bottom: 1rem;
    }
    &-mood {
      cursor: pointer;
      width: 30%;
      background-color: transparent;
      border: 0;
      outline: none;
      @extend %flex-col-center-between;
      cursor: pointer;
      position: relative;
      img {
        width: 65px;
        object-fit: contain;
        border: 2px solid transparent;
        border-radius: 10rem;
        box-sizing: content-box;
        padding: 0.3rem;
      }
      p {
        margin: 0.5rem 0;
        font-family: 'Inter Bold';
        font-size: 12px;
        color: $dark;
        text-transform: uppercase;
      }
    }
    &-btn {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      width: 100%;
      height: 65px;
      @extend %flex-row-center-center;
      background-color: $greyDark;
      border: 0;
      outline: none;
      padding: 1rem;
      cursor: pointer;
      margin-top: auto;
      z-index: 100;
      span {
        font-family: 'Inter Bold';
        font-size: 13px;
        color: $yellow;
        margin-left: 1rem;
      }
    }
  }
  &--container {
    height: 100%;
    background-color: transparent;
    @extend %flex-col-start-start;
    transition: all 0.3s;
  }
}
