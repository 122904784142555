.gallery {
  width: 100%;
  height: 170px;
  @extend %flex-row-center-between;
  margin-top: 0.5rem;
  margin: 1rem 0 3rem;
  label {
    width: 22%;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  &--uploader {
    width: 180px;
    height: 170px;
    border: 1px dashed $grey;
    border-radius: 6px;
    @extend %flex-col-center-center;
    &-texts {
      font-family: 'Inter Semi Bold';
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      color: $dark;
      text-align: center;
    }
  }

  &--imgBlock {
    width: 190px;
    height: 165px;
    border: 2px solid #efefef;
    border-radius: 6px;
    padding: 0.5rem;
    position: relative;
    @extend %flex-col-center-center;
    margin: 0.5rem;
    p {
      position: absolute;
      bottom: -2.5rem;
      font-family: 'Inter Medium';
      font-size: 14px;
      line-height: 20px;
      color: $dark;
    }
    &-img {
      width: 90%;
      height: 90%;
      object-fit: contain;
      @extend %flex-col-center-center;
    }

    &-close {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      padding: 0.2rem 0.3rem;
      @extend %flex-col-center-center;
      background-color: crimson;
      color: $white;
      cursor: pointer;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      font-size: 14px;
    }
  }

  &--carousel {
    width: 77%;
    height: 100%;
    // position: relative;
    @extend %flex-row-center-between;
    &-btn {
      width: 36px;
      background-color: #dbe3e5;
      height: 100%;
      outline: none;
      border: 0;
      cursor: pointer;
    }
    &-items {
      width: 100%;
      @extend %flex-row-center;
      //   overflow-x: auto;
      scroll-behavior: smooth;
    }
  }
}

.imgDownload {
  @extend %flex-col-center-center;
  position: relative;
  margin: 1rem auto;
  // p {
  //   font-family: 'Inter Medium';
  //   font-size: 16px;
  //   color: $dark;
  //   margin: 0 0 1rem;
  //   text-transform: capitalize;
  // }
  &--imgBlock {
    width: 280px;
    height: 180px;
    @extend %flex-col-center-center;
    position: relative;
    box-shadow: 0 0 5px rgba(40, 40, 40, 0.2);
    border-radius: 13px;
    overflow: hidden;
    &-close {
      width: 25px;
      height: 25px;
      background-color: #ffffff;
      border-radius: 6px;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      z-index: 50;
      border: 0;
      outline: none;
      color: #9ca3af;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
    }
    &-label {
      width: 100%;
      height: 100%;
      @extend %flex-col-center-center;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
      }
    }
    &-btn {
      @extend %flex-col-center-center;
      position: absolute;
      z-index: 50;
      cursor: pointer;
    }
    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      width: 50px;
      height: 50px;
      display: none;
      cursor: pointer;
      border-radius: 50px;
      outline: none;
      border: 0;
      margin: 0 0.2rem;
      background-color: $dark;
      transition: 0.3s;
      &:hover {
        transition: 0.3s;
        @extend %flex-col-center-center;
      }
    }
    &-img:hover + &-icon {
      transition: 0.3s;
      @extend %flex-col-center-center;
    }
    &-btnDownload {
      width: 50px;
      height: 50px;
      @extend %flex-col-center-center;
      margin-top: 0.5rem;
      background-color: black;
      text-decoration: none;
      outline: none;
      cursor: pointer;
      border-radius: 50px;
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        transform: scale(1.04);
      }
    }
    &-btnAction {
      @extend %flex-col-center-center;
    }
  }
}

.imgDownloadMini {
  width: 90%;
  @extend %flex-row-center-between;
}
