.header {
  height: 10vh;
  width: 100%;
  padding: 1rem 3rem;
  background-color: white;
  @extend %flex-row-center-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 300;
  &--title {
    font-family: 'Inter Semi Bold';
    font-size: 19px;
    text-align: center;
    text-transform: uppercase;
    color: $dark;
  }
  &--actionModal {
    padding: 1rem 0.5rem;
    background-color: white;
    position: fixed;
    top: 75px;
    right: 1rem;
    box-shadow: 1px 1px 10px #e8e8e8;
    border-radius: 4px;
    z-index: 200;
    @extend %flex-col-start-start;
    button {
      background-color: transparent;
      border: 0;
      padding: 0.8rem 1rem;
      box-shadow: none !important;
      @extend %flex-row-center-start;
      cursor: pointer;
      span {
        margin-left: 0.5rem;
      }
    }
  }
  &--input {
    width: 90%;
    border-radius: 6px;
    height: 45px;
    background-color: #f5f5f5;
    padding-left: 10px;
    @extend %flex-row-center-around;
    input {
      outline: none;
      width: 92%;
      border-radius: 6px;
      height: 45px;
      border: none;
      background-color: #f5f5f5;
      padding: 0.5rem;
      @extend %h3-typo;
      @extend %flex-row-center-around;
    }
  }
  &--avatar {
    background-color: transparent;
    outline: none;
    border: 0;
    cursor: pointer;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    img {
      width: 45px;
      object-fit: contain;
    }
  }
}

.headerUser {
  width: 100%;
  height: auto;
  padding: 1rem 3rem 0;
  @extend %flex-col-start-start;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  &--header {
    width: 100%;
    @extend %flex-row-center-between;
    padding: 1rem;
    background-color: $white;
  }
  &--headerLeft {
    width: 50%;
    @extend %flex-row-center-start;
    span {
      font-family: 'Inter Bold';
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: $dark;
      margin-left: 1rem;
    }
  }
  &--tabs {
    width: 100%;
    @extend %flex-row-center-start;
    background-color: $white;
    // margin-top: 1rem;
    &-btn {
      width: 50%;
      height: 52px;
      border: 0;
      background-color: $white;
      outline: none;
      box-shadow: 0 0 5px $grey;
      border-radius: 6px 6px 0 0;
      @extend %flex-row-center-center;
      font-family: 'Inter Medium';
      font-size: 14px;
      line-height: 20px;
      color: $dark;
      position: relative;
      cursor: pointer;
      &-active {
        color: $yellow;
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: $yellow;
          position: absolute;
          bottom: 0;
          z-index: 100;
        }
      }
    }
  }
  &--tabsSticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 150;
    padding: 0.5rem 1rem;
  }
}
