.imagesListModal {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: $white;
  @extend %flex-col-start-start;
  overflow: hidden;
  margin: 0 auto;
  z-index: 500;
  top: 0;
  left: 0;
  position: absolute;
  &--bg {
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 300;
  }
}
