.collapse {
  @extend %flex-col-start-start;
  &--btn {
    width: 100%;
    @extend %flex-row-center-between;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    border-bottom: 1px solid $grey;
    outline: none;
    padding: 1.5rem 2rem;
    span {
      @extend %h1-typo;
      color: $dark;
    }
  }
  &--content {
    width: 100%;
    @extend %flex-wrap;
    padding: 1rem;
    border-bottom: 1px solid $grey;
  }

  &--right,
  &--checkbox {
    display: flex;
    align-items: center;

    &-label {
      margin-right: 1rem;
    }
  }

  &--counter {
    display: inline-block;
    color: #a88e1b !important;
    font-family: 'Inter Medium' !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400;
    line-height: 20px;
    margin-left: 1rem;
  }

  &--checkbox {
    margin-right: 2rem;
  }
}

.filterImgCollapse {
  width: 100%;
  min-height: 55px;
  background-color: $white;
  @extend %flex-col-start-start;
  &--btn {
    width: 100%;
    height: 55px;
    padding: 0.5rem 1.3rem;
    @extend %flex-row-center-between;
    background-color: $white;
    border: 0;
    outline: none;
    cursor: pointer;
    border-bottom: 1px solid #f5f5f5;
    span {
      font-family: 'Inter Bold';
      font-size: 13px;
      letter-spacing: 0.005em;
      text-transform: uppercase;
      color: $dark;
    }
  }
  &--container {
    width: 100%;
    @extend %flex-col-start-start;
  }
}
