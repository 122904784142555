.images {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @extend %flex-col-start-start;
  &--navigate {
    width: 100%;
    height: 100%;
    padding: 3rem;
    @extend %flex-row-start-center;
    background-color: #f9fafb;
    &-btn {
      width: 250px;
      height: 160px;
      background: #ffffff;
      border: 1px solid #000000;
      border-radius: 50px;
      outline: none;
      cursor: pointer;
      @extend %flex-col-center-center;
      margin: 1rem;
      transition: 0.3s;
      p {
        font-family: 'Inter Bold';
        font-size: 18px;
        color: $dark;
      }
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  &--tabs {
    width: 100%;
    @extend %flex-row-center-start;
    // margin-top: 1rem;
    &-btn {
      width: 50%;
      height: 52px;
      border: 0;
      background-color: $white;
      outline: none;
      box-shadow: 0 0 5px $grey;
      border-radius: 6px 6px 0 0;
      @extend %flex-row-center-center;
      font-family: 'Inter Medium';
      font-size: 14px;
      line-height: 20px;
      color: $dark;
      position: relative;
      cursor: pointer;
      &-active {
        color: $yellow;
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: $yellow;
          position: absolute;
          bottom: 0;
          z-index: 100;
        }
      }
    }
  }
  &--imgsLength {
    @extend %flex-row-center-start;
    margin: 1rem;
    span {
      font-family: 'Inter Bold';
      font-size: 18px;
      color: $dark;
      margin-left: 1rem;
    }
  }
  &--container {
    width: 100%;
    margin: 0px auto;
    columns: 4;
    column-gap: 10px;
    &-img {
      background-color: transparent;
      border: 0;
      outline: none;
      border-radius: 10px;
      width: 100%;
      min-height: 100px;
      margin: 0 0.5rem 0.5rem 0;
      transition: all 0.5s;
      position: relative;
      &:hover {
        transform: scale(1.03);
      }

      img {
        width: 100%;
        height: 100%;

        border-radius: 10px;
        object-fit: contain;
      }
      &-zoom,
      &-attr {
        position: absolute;
        z-index: 10;
        // display: none;
        cursor: pointer;
        outline: none;
        border: 0;
        margin: 0 0.2rem;
        background-color: $dark;
        transition: 0.3s;
        @extend %flex-col-center-center;
        &:hover {
          transition: 0.3s;
        }
      }
      &-zoom {
        width: 50px;
        height: 50px;
        top: 50%;
        border-radius: 50px;
        left: 50%;
        transform: translate(-50%, -50%);

        &.small {
          width: 25px;
          height: 25px;
          top: inherit;
          left: 0.3rem;
          transform: none;
          bottom: 0.5rem;

          svg {
            width: 14px;
          }
        }
      }
      &-attr {
        width: 25px;
        height: 25px;
        border-radius: 35px;
        bottom: 1rem;
        right: 1rem;
        background-color: $yellow;

        &.small {
          bottom: 0.5rem;
          right: 0.3rem;

          svg {
            width: 14px;
          }
        }
      }
    }
  }
}

.image {
  width: 100%;
  height: 100vh;
  @extend %flex-col-center-start;
  background-color: $grey;
  overflow-y: auto;
  &--container {
    width: 70%;
    background: $white;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem 5rem;
    margin-top: 1rem;
    @extend %flex-col-start-start;
  }
  &--downloadImgs {
    width: 100%;
    @extend %flex-row-center-around;
    margin: 2rem 0;
  }
  &--imageProducts {
    width: 100%;
    @extend %flex-col-start-start;
    border: 1px solid $grey;
    margin: 1rem 0;
    border-radius: 8px;
  }
  &--imageProduct {
    width: 100%;
    height: 60px;
    @extend %flex-row-center-between;
    border-bottom: 1px solid $grey;
    padding: 0 1rem;
    &-label {
      font-family: 'Inter Medium';
      font-size: 12px;
      text-transform: uppercase;
      color: $dark;
    }
    &-btns {
      @extend %flex-row-center;
    }
    &-quizBadge {
      padding: 0.5rem 0.8rem;
      background-color: #fafafa;
      border: 1px solid $grey;
      border-radius: 50px;
      @extend %flex-row-center-center;
      font-family: 'Inter Bold';
      font-size: 12px;
      color: $dark;
      margin-right: 1rem;
    }
  }
  &--attributionNames {
    width: 100%;
    @extend %flex-col-start-start;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem 4rem;
    margin-bottom: 1rem;
  }
  &--attributions {
    width: 100%;
    @extend %flex-col-start-start;
    &-count {
      width: 170px;
      height: 45px;
      background-color: #f2caca;
      border-radius: 3px;
      @extend %flex-row-center-center;
      font-family: 'inter bold';
      font-size: 14px;
      color: #cf1f1f;
    }
    &-list {
      width: 100%;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      padding: 1rem;
    }
  }
  &--sectionTitle {
    font-family: 'Inter Bold';
    font-size: 16px;
    color: $dark;
  }
  &--actionBtn {
    @extend %flex-row-center-end;
    width: 100%;
    &-item {
      @extend %flex-row-center-between;
      button:last-child {
        margin-left: 1rem !important;
      }
    }
  }

  &--btnPlus {
    width: 90px;
    height: 83px;
    background: #f5f5f5;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 0;
    outline: none;
    margin: 0 0.5rem 0.5rem 0;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 14px;
    font-family: 'Inter Medium';

    @extend %flex-col-center-center;
    &:hover {
      background-color: #f4f4f4;
      color: $yellow;
    }
  }
  &--footer {
    width: 100%;
    height: 80px;
    padding: 1rem 0;
    @extend %flex-row-center-end;
    border-top: 1px solid $greyDark;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 100;
    background-color: $white;
    margin-top: 1rem;
  }
}

.imageAttributions {
  width: 100%;
  height: 100vh;
  @extend %flex-row-start-between;
  overflow: hidden;
  &--title {
    font-family: 'Inter Bold';
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: $dark;
  }
  &--leftBlock {
    width: 30%;
    height: 100%;
    @extend %flex-col-start-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    background-color: $whiteBlue;
    gap: 1rem;
    &-imgBlock {
      width: 100%;
      height: 60%;
      @extend %flex-col-center-start;
      overflow: hidden;
      img {
        width: 100%;
        object-fit: contain;
        border-radius: 1rem;
      }
    }
    &-cards {
      width: 100%;
      @extend %flex-wrap;
      gap: 1rem;
    }
  }

  &--centerBlock {
    width: 40%;
    height: 100%;
    @extend %flex-col-start-start;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
  }
  &--rightBlock {
    width: 30%;
    height: 100%;
    @extend %flex-col-center-between;
    padding: 1rem;
    background-color: $whiteBlue;
    &-list {
      width: 100%;
      height: 90%;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      @extend %flex-col-center-start;
      gap: 1rem;
      overflow-y: auto;
    }
    &-text {
      @extend %h2-typo;
      color: $dark;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      background-color: $whiteBlue;
      padding: 0.5rem;
      z-index: 100;
    }
    &-filterBtn {
      width: 100%;
      height: 40px;
      background-color: #f5f5f5;
      border-radius: 8px;
      @extend %flex-row-center-between;
      padding: 0 0.5rem;
      border: 1px solid #dfdfdf;
      margin: 0.4rem 0;
      cursor: pointer;
      span {
        &:first-child {
          @extend %h2-typo;
          color: $dark;
        }
        &:last-child {
          width: 25px;
          height: 25px;
          border: 2px solid #dbdbdb;
          border-radius: 25px;
        }
      }
    }
    &-maybeComps {
      width: 95%;
      padding: 1rem;
      @extend %flex-col-start-start;
      margin-inline: auto;
      border-radius: 1rem;
      gap: 1rem;
      overflow-y: auto;
      background-color: $red;
      &-questions {
        width: 100%;
        @extend %flex-col-start-start;
        gap: 0.5rem;
        padding-inline: 1rem;
        margin-bottom: 1rem;
        p {
          &:first-child {
            @extend %h2-typo;
          }
          &:last-child {
            @extend %h3-typo;
          }
          color: $white;
          margin: 0;
          padding: 0;
          line-height: 1.2rem;
        }
      }
    }
  }
  &--card {
    width: 145px;
    height: 80px;
    @extend %flex-col-center-center;
    background-color: #fafafa;
    border: 2px solid;
    border-radius: 10px;
    padding: 1rem;
    font-family: 'Inter Bold';
    font-size: 14px;
    text-align: center;
    color: $dark;
    cursor: pointer;
  }
  &--tabs {
    width: 100%;
    @extend %flex-row-center-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  &--tab {
    height: 50px;
    @extend %flex-col-center-center;
    background-color: #fafafa;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    padding: 1rem;
    font-family: 'Inter Bold';
    font-size: 14px;
    color: $dark;
    cursor: pointer;
  }
}
