.button {
  padding: 9px 17px;
  height: 40px;
  @extend %flex-row-center-center;
  cursor: pointer;
  border: inherit;
  outline: inherit;
  font-size: 12px;
  transition: 0.3s;
  &--fixed {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 100;
  }
  &:hover {
    transform: scale(1.02);
  }
}

.buttonAdd {
  @extend %flex-row-center-center;
  box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  span {
    &:first-child {
      width: 31px;
      height: 31px;
      border-radius: 31px;
      @extend %flex-col-center-center;
      background-color: $greyDark;
      margin-right: 0.7rem;
    }
    &:last-child {
      font-family: 'Inter Bold';
      font-size: 14px;
      color: $yellow;
      text-transform: inherit !important;
    }
  }
}

.buttonRadio {
  @extend %flex-row-center-center;
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  span {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 1px solid $yellow;
    margin-right: 0.5rem;
    transition: 0.3s;
  }
}
