.packages {
  // width: 100%;
  // @extend %flex-col-start-start;
  // padding: 2rem;
  &--header {
    width: 100%;
    @extend %flex-row-end-between;
    margin-bottom: 1rem;
  }
  &--container {
    width: 100%;
    @extend %flex-col-start-start;
    padding: 1rem;
    background-color: white;
    box-shadow: 0px 0px 25px -5px rgba(0, 0, 0, 0.1),
      0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
  }
  &--footer {
    width: 100%;
    @extend %flex-col-end-center;
    padding: 1rem;
    margin-top: auto;
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 8px;
    height: 80px;
    position: sticky;
    position: -webkit-sticky;
    right: 0;
    bottom: 0;
    z-index: 150;
  }
  &--date {
    width: 114px;
    height: 134px;
    border: 1px solid $grey;
    border-radius: 10px;
    @extend %flex-col-center-center;
    margin: 0 1rem 1rem 0;
    button {
      width: 91px;
      height: 91px;
      background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
      box-shadow: 1px 1px 1px rgba(40, 40, 40, 0.2);
      border-radius: 13px;
      border: 0;
      outline: none;
      cursor: pointer;
      font-family: 'Inter Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      color: $dark;
    }
    p {
      @extend %h3-typo;
      color: $dark;
      margin: 0.5rem 0 0;
    }
  }
}
