.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin: 0.3rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 26px;
  transition: 0.4s;
}

.switch-slider::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .switch-slider::before {
  transform: translateX(24px);
}
