.withNavigation {
  width: 100%;
  height: 100vh;
  @extend %flex-row-start-between;
  // overflow: hidden;
  // &--contain {
  //   width: 80%;
  //   height: 100%;
  //   @extend %flex-col-center-start;
  // }
  &--container {
    position: relative;
    background-color: $whiteBlue;
    width: 80%;
    height: 100%;
    @extend %flex-col-start-start;
    overflow-y: auto;
  }
}
