.version {
  position: absolute;
  bottom: 2rem;
  z-index: 10;
  font-size: 12px;
  color: $dark;
}
.btn {
  &--neutral {
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    @extend %h3-typo;
    color: $dark;
  }
}

.upload {
  width: auto;
  @extend %flex-row-center;
  margin: 1rem 0;
  position: relative;
  &--label {
    background-color: $white;
    border: 1px solid $greyDark;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 0.5rem;
    cursor: pointer;
    margin-left: 1rem;
  }
}

.table {
  width: 100%;
  background-color: $white;
  @extend %flex-col-start-start;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  &-trow {
    width: 100%;
    height: 70px;
    @extend %flex-row-center-between;
    border-bottom: 1px solid $grey;
    padding: 0 1rem;
    &-textIgmBlock {
      @extend %flex-row-center;
    }
    &-text {
      @extend %h5-typo;
      color: $dark;
      text-transform: uppercase;
    }
    &-action {
      position: relative;
      @extend %flex-col-center-center;
    }
    &-btn {
      height: 55px !important;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: $grey;
      }
    }
    &-active {
      border: 1px solid $yellow !important;
    }
  }
}

.actionModal {
  width: 224px;
  min-height: 89px;
  background-color: $white;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: absolute;
  right: 2rem;
  top: 0;
  button {
    width: 100%;
    height: 44px;
    background-color: transparent;
    border: 0;
    outline: none;
    @extend %flex-row-center-start;
    cursor: pointer;
    transition: 0.3s;
    padding-left: 1rem;
    border-bottom: 1px solid $grey;
    &:last-child {
      border: 0;
    }
    &:hover {
      padding-left: 1.5rem;
    }
  }
}

.pages-layout {
  width: 100%;
  height: 100%;
  @extend %flex-col-start-start;
  overflow-y: auto;
  &--container {
    padding: 1rem 3rem;
    width: 100%;
    box-sizing: border-box;
  }
}

.initial {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  padding: 1rem;
  background-color: $dark;
  @extend %flex-row-center-center;
  color: $white;
  text-transform: uppercase;
  font-size: 18px;
}

//Accordion
.accordion {
  width: 47%;
  min-height: 40px;
  @extend %flex-col-start-start;
  padding: 0.6rem;
  background-color: $grey;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 1rem 1rem 0;
  &--btn {
    padding: 0 0.5rem;
    width: 100%;
    height: 35px;
    @extend %flex-row-center-start;
  }
  &--check {
    width: 23px;
    height: 23px;
    border-radius: 25px;
    display: inline-block;
    border: 2px solid $greyDark;
    margin-right: 0.7rem;
    cursor: pointer;
  }
  &--title {
    width: calc(100% - 30px);
    font-family: 'Inter Medium';
    font-size: 12px;
    color: $dark;
    margin: 0 0 0 auto;
    @extend %flex-row-center-start;
    height: 35px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
  }
  &--badge {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $yellow;
    display: inline-block;
    margin-left: 0.5rem;
  }
  &--content {
    width: 100%;
    @extend %flex-col-start-start;
    overflow: hidden;
  }
}

.item-loader {
  width: 100%;
  height: 100%;
  @extend %flex-col-center-center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: white;
  border-radius: 4px;
  img {
    width: 40px;
    // height: 40px;
  }
}

.blurhash {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  overflow: hidden;
  border-radius: 10px;
}
.blurhash-diary {
  position: absolute;
  left: 3.2rem;
  width: 64px;
  height: 64px;
  border-radius: 13px;
  overflow: hidden;
  z-index: 20;
}

.buttonSelect {
  width: 100%;
  height: 45px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  outline: none;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  @extend %flex-row-center-start;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: $greyDark;
  }
}

.imgGridLayout {
  width: 100% !important;
}

//Flex utility
.flex-row-center {
  @extend %flex-row-center;
}
.flex-row-center-start {
  @extend %flex-row-center-start;
}
.flex-wrap {
  @extend %flex-wrap;
}
.flex-row-center-between {
  @extend %flex-row-center-between;
}
.flex-row-center-around {
  @extend %flex-row-center-around;
}
.w-100 {
  width: 100%;
}

.lds-roller {
  // display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  transform: scale(0.8);
  left: 0;
  right: 0;
  display: flex;
  justify-self: center;
  align-items: center;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #282828;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}

.infinite-scroll-component {
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  &__outerdiv {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}
