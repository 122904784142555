.strategyModelModal {
  &--container {
    width: 100%;
    @extend %flex-col-center-start;
  }
  &--model {
    width: 100%;
    @extend %flex-row-end-between;
    margin-bottom: 1rem;
    &-number {
      font-family: 'Inter Semi Bold';
      color: $greyDark;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-btns {
      @extend %flex-row-center;
    }
    &-btnAdd,
    &-btnRemove {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      @extend %flex-col-center-center;
      margin: 0 0.3rem;
      outline: none;
      border: 0;
      font-family: 'Inter Bold';
      font-size: 16px;
      color: $white;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }
    &-btnAdd {
      background-color: $dark;
    }
    &-btnRemove {
      background-color: #d44f4d;
    }
  }
  &--footer {
    width: 100%;
    min-height: 60px;
    padding: 0.5rem 1rem;
    @extend %flex-row-center-end;
    position: sticky;
    position: -webkit-sticky;
    bottom: -1rem;
    background-color: $white;
  }
}
