.noSignal {
  width: 100%;
  height: 100vh;
  @extend %flex-col-center-center;
  img {
    width: 40%;
  }
  p {
    font-family: 'Inter Bold';
    font-size: 14px;
  }
}
