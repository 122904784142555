.familiesModal {
  &--ingredients {
    width: 100%;
    @extend %flex-col-start-start;
    p {
      @extend %h3-typo;
      color: $dark;
      margin-bottom: 0.4rem;
    }
    &-list {
      width: 100%;
      min-height: 100px;
      max-height: 350px;
      background-color: $white;
      @extend %flex-wrap;
      justify-content: flex-start;
      overflow-y: auto;
      margin: 0.5rem 0;
      border: 1px solid $grey;
      border-radius: 6px;
      padding: 0.5rem 0;
      box-sizing: border-box;
    }
  }
}
