.tabs {
  width: 100%;
  @extend %flex-row-center-start;
  &--btn {
    flex: 1;
    height: 52px;
    border: 0;
    background-color: $white;
    outline: none;
    box-shadow: 0 0 5px $grey;
    border-radius: 6px 6px 0 0;
    @extend %flex-row-center-center;
    font-family: "Inter Medium";
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    position: relative;
    cursor: pointer;
    &-active {
      color: $yellow;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: $yellow;
        position: absolute;
        bottom: 0;
        z-index: 100;
      }
    }
  }
}
