@font-face {
  font-family: "Inter Bold";
  src: url("../assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter Medium";
  src: url("../assets/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter Regular";
  src: url("../assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter Semi Bold";
  src: url("../assets/fonts/Inter-SemiBold.ttf");
}
