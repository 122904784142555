.modal {
  max-height: 90%;
  overflow-y: auto;
  background-color: $white;
  border-radius: 8px;
  @extend %flex-col-center-between;
  margin: 0 auto;
  padding: 1rem 2rem;
  z-index: 500;
  top: 5%;
  left: 40%;
  position: absolute;
  &--bg {
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    width: 80%;
    z-index: 300;
  }

  &--header {
    @extend %flex-row-center-center;
    width: 100%;
    height: 45px;
    border-bottom: 1px solid $grey;
    padding: 0 0 1rem;
  }
  &--title {
    @extend %h1-typo;
    line-height: 24px;
    text-align: center;
    color: $dark;
    margin: auto;
    text-transform: uppercase;
  }
  &--content {
    width: 100%;
    @extend %flex-col-center-start;
  }
  &--footer {
    width: 100%;
    @extend %flex-row-center-center;
  }

  &--imgView {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.modalImage {
  &--bg {
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 300;
  }
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $white;
  // border-radius: 8px;
  @extend %flex-col-center-start;
  margin: 0 auto;
  padding: 0 4rem;
  z-index: 500;
  top: 0;
  left: 0;
  position: absolute;
  &--container {
    width: 90%;
    background: $white;
    border: 1px solid #f4f4f4;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 1rem 5rem;
    margin-top: 1rem;
    @extend %flex-col-start-start;
  }
  &--historicals {
    width: 100%;
    @extend %flex-col-start-start;
    &-thead {
      width: 100%;
      height: 45px;
      @extend %flex-row-center-start;
      background-color: $grey;
      padding: 0 1rem;
      border-radius: 4px;
      span {
        flex: 1;
        font-size: 14px;
        font-weight: 600;

        &:nth-child(2) {
          flex: 3;
        }
      }
    }
    &-trow {
      width: 100%;
      min-height: 45px;
      @extend %flex-row-start-start;
      border-bottom: 1px solid $grey;
      padding: 1rem;
      span {
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5rem;
        &:nth-child(2) {
          flex: 3;
        }
      }
    }
  }
  &--footer {
    width: 100%;
    height: 80px;
    padding: 1rem 0 0;
    @extend %flex-row-center-end;
    border-top: 1px solid $greyDark;
    margin-top: auto;
  }
}

.modalImageMini {
  width: 1000px;
  height: 95%;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding-top: 1rem;
  transform: translate(10px, 10px);

  &--container {
    width: 100%;
    background: $white;
    border: 1px solid transparent;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0), 0px 1px 2px rgba(0, 0, 0, 0);
    border-radius: 8px;
    padding: 1rem;
    @extend %flex-col-start-start;
  }

  &--attributionNames {
    width: 100%;
    padding: 0 1rem;
  }
}

.modalImagesSimilars {
  &--bg {
    position: absolute;
    background-color: rgba($color: #000000, $alpha: 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 300;
  }
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $white;
  @extend %flex-col-center-start;
  margin: 0 auto;
  padding: 0 1rem;
  z-index: 500;
  top: 0;
  left: 0;
  position: absolute;
  &--title {
    font-size: 20px;
    font-family: 'Inter Bold';
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  &--header {
    position: relative;
    width: 100%;
    @extend %flex-row-center-between;
    background-color: $white;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid $grey;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 50;
  }
  &--filter {
    width: 60%;
    position: relative;
    @extend %flex-col-center-start;
    &-btn {
      width: 100%;
      height: 45px;
      @extend %flex-row-center-between;
      border: 1px solid $grey;
      border-radius: 4px;
      background-color: white;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
  &--searchBar {
    width: 100%;
    height: 45px;
    @extend %flex-row-center-start;
    border: 1px solid $grey;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    padding: 1.5rem 0.5rem;
    box-sizing: border-box;
    input {
      border: 0;
      outline: none;
      flex: 1;
      height: 100%;
      padding: 1.5rem 0.5rem;
    }
    button {
      background-color: $yellow;
      border: 0;
      outline: 0;
      color: $white;
      font-family: 'Inter Medium';
      width: 100px;
      height: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      margin-left: 0.5rem;
    }
  }
  &--select {
    width: 100%;
    min-height: 60px;
    max-height: 300px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 4px;
    overflow-y: auto;
    @extend %flex-col-center-start;
    z-index: 30;
    padding: 1rem;
    box-sizing: border-box;
  }
  &--footer {
    width: 100%;
    @extend %flex-row-center-end;
    margin-top: auto;
    border-top: 1px solid $grey;
    padding: 1rem;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 10;
    background-color: $white;
  }
}
