.notFoundLayout {
  width: 100%;
  height: 200px;
  background-color: $whiteBlue;
  @extend %flex-col-center-center;
  border-radius: 10px;
  &--text {
    font-size: 18px;
    font-family: 'Inter Semi Bold';
    color: $red;
  }
}
