.tableHeader {
  width: 100%;
  @extend %flex-row-center-start;
  margin-bottom: 1rem;
  &--title {
    @extend %h1-typo;
    color: $dark;
    text-transform: uppercase;
  }
  &--backBtn {
    width: 45px;
    height: 45px;
    background: $grey;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    @extend %flex-col-center-center;
    margin-right: 1rem;
    border: 0;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      transform: scale(1.03);
    }
  }
}
